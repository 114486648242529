body {
  background: url('/assets/images/error-404.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

}

.page-error {

  background: rgba(255, 255, 255, 0.5);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.page-error h2, .page-error h2 small, .page-error p {
  color: #0e0e0e !important;
}

.page-error a {
  color: #0e0e0e !important;
  font-weight: bold;
}

//.message {
//    opacity: 1;
//}